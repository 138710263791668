import * as React from 'react';
import { Container } from "reactstrap";
import "./styles.css";
import classNames from "classnames";

export default function ({
  size = 'medium',
  children,
}) {
  return <Container className={classNames('container', size)}>{ children }</Container>;
}
