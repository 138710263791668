import classnames from "classnames";
import {Container} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Link} from "gatsby";

function Card({
	card,
}) {
	const {
		id,
		title,
		description,
		icon,
	} = card;

	return (
		<div className="card" key={id}>
			<div className="card-icon">
				<FontAwesomeIcon icon={["fa", icon]} size="2x" />
			</div>
			<div className="card-content">
				<div className="card-title">
					{ title }
				</div>
				<div className="card-description">
					{ description }
				</div>
			</div>
		</div>
	);
}

function CardGroup({
	group,
	onInternalClick
}) {
	const {
		id,
		title,
		cards,
	} = group;

	return (
		<div className="cards-group" key={id}>
			<div className="title">
				{ title }
			</div>
			<div className="cards">
				{ cards.map((card) => (
					card.type === 'internal' ? (
						<Link to={card.link} onClick={() => onInternalClick(id)}>
							<Card card={card} />
						</Link>
					) : null
				))}
			</div>
		</div>
	);
}

export default function({
	navItems,
  openedDropDown,
  onInternalClick,
}) {
	if (!openedDropDown) {
		return null;
	}

	const activeDropdown = navItems.find(({ id }) => openedDropDown === id);

	if (!activeDropdown) {
		return null;
	}

	const {
		items,
	} = activeDropdown;

	return (
		<div className={classnames('nav-dropdown', 'active')}>
			<div className="nav-dropdown-actions">
				<FontAwesomeIcon icon={["fa", "times"]} onClick={onInternalClick} />
			</div>

			<Container className="nav-dropdown-container">
				<div className="cards-wrapper">
					{
						items.map((item) => <CardGroup group={item} onInternalClick={onInternalClick} />)
					}
				</div>
			</Container>
		</div>
	);
}
