import * as React from 'react';
import { Link } from "gatsby"
import { NavItem, Nav } from "reactstrap";
import classnames from 'classnames';
import "./navigator.css";

function Item({ item, onDropDownClick, onInternalClick, openedDropDown }) {
	const {
		id,
		type,
		label,
		link,
		classes,
	} = item;

	return (
		<NavItem className={classnames(classes, {
			'active': openedDropDown === id,
		})}>
			<div className="nav-link">
				{
					type === 'internal' ? (
						<Link to={ link } onClick={() => onInternalClick(id)}>
							{label}
						</Link>
					) : (type === 'external' ? (
						<a href={link}>{label}</a>
					) : (type === 'dropdown' ? (
						<a href="void(0)" onClick={() => onDropDownClick(id)}>{label}</a>
					) : null))
				}
			</div>
		</NavItem>
	);
}

export default function({ navItems, onDropDownClick, onInternalClick, openedDropDown }) {
	return (
		<Nav className="nav navbar-nav ml-auto" navbar>
			{
				navItems.map((item) => <Item
					item={item}
					key={item.id}
					openedDropDown={openedDropDown}
					onDropDownClick={onDropDownClick}
					onInternalClick={onInternalClick} />)
			}
		</Nav>
	);
}
