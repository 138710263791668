import React from "react";
import { Link } from "gatsby"
import classNames from "classnames/bind";
import { Link as ScrollLink } from 'react-scroll'
import classnames from 'classnames';
import Navigator from './Navigator';
import NavigatorDropdowns from './NavigatorDropdowns';
import LogoWhite from './logo/logo-white.png';
import LogoDark from './logo/logo-dark.png';

// reactstrap components
import { NavbarBrand } from "reactstrap";
import Container from "../container/Container";

function RedirectLink (props) {
	return <Link to={props.to} onClick={props.onClick}>{props.children}</Link>
}

const navItems = [
	/*{
		id: 'features',
		label: 'Features',
		type: 'dropdown',
		items: [
			{
				id: 'develop',
				title: 'Develop',
				cards: [
					{
						id: 'entities',
						title: 'Data model',
						description: 'Build the data model structure in a visual editor',
						icon: 'database',
						link: '/develop/data-model',
						type: 'internal',
					},
					{
						id: 'routers',
						title: 'Api endpoints',
						description: 'Give access to the data model in just a few clicks',
						icon: 'layer-group',
						link: '/develop/api-endpoints',
						type: 'internal',
					},
					{
						id: 'flows',
						title: 'Functional flows',
						description: 'Create advanced authorization flows',
						icon: 'retweet',
						link: '/develop/functional-flows',
						type: 'internal',
					},
				],
			},
			{
				id: 'review',
				title: 'Review',
				cards: [
					{
						id: 'code',
						title: 'Source code',
						description: 'Compare the source code changes between builds',
						icon: 'code',
						link: '/review/source-code',
						type: 'internal',
					},
				],
			},
			{
				id: 'integrate',
				title: 'Integrate',
				cards: [
					{
						id: 'edit',
						title: 'Business logic',
						description: 'Use your IDE to do any changes on the source code',
						icon: 'edit',
						link: '/integrate/business-logic',
						type: 'internal',
					},
				],
			},
			{
				id: 'deliver',
				title: 'Deliver',
				cards: [
					{
						id: 'cloud',
						title: 'Environment deploy',
						description: 'Set up environments on AWS or Docker in a few clicks',
						icon: 'cloud',
						link: '/deploy/environment',
						type: 'internal',
					},
				],
			},
		],
	},*/
	/*{
		id: 'resources',
		label: 'Resources',
		type: 'dropdown',
		items: [
			{
				id: 'community',
				title: 'Community',
				cards: [
					{
						id: 'community',
						title: 'Community',
						description: 'Get latest news about the projects, programs and events',
						icon: 'calendar',
						link: '/resources/community',
						type: 'internal',
					},
					{
						id: 'blog',
						title: 'Blog',
						description: 'See the latest news and best practices',
						icon: 'rss',
						link: '/blog',
						type: 'internal',
					},
				],
			},
			{
				id: 'tutorials',
				title: 'Tutorials',
				cards: [
					{
						id: 'documentation',
						title: 'Documentation',
						description: 'Learn more about how Mockless works',
						icon: 'file',
						link: '/docs',
						type: 'internal',
					},
					{
						id: 'showcase',
						title: 'Showcase',
						description: 'See Mockless in action',
						icon: 'video',
						link: '/showcase',
						type: 'internal',
					},
				],
			},
			{
				id: 'support',
				title: 'Support',
				cards: [
					{
						id: 'forum',
						title: 'Forum',
						description: 'Get help from the community',
						icon: 'comments',
						link: '/forum',
						type: 'internal',
					},
					{
						id: 'contact',
						title: 'Contact us',
						description: 'Need more information? Drop us a message!',
						icon: 'paper-plane',
						link: '/contact',
						type: 'internal',
					},
				],
			},
		],
	},*/
	{
		id: 'home',
		label: 'Home',
		link: '/',
		type: 'internal',
	},
	{
		id: 'pricing',
		label: 'Pricing',
		link: '/pricing',
		type: 'internal',
	},
	{
		id: 'blog',
		label: 'Blog',
		link: '/blog',
		type: 'internal',
	},
	{
		id: 'contact',
		label: 'Contact us',
		link: '/contact',
		type: 'internal',
	},
	{
		id: 'login',
		label: `Login`,
		type: 'external',
		link: `${process.env.REACT_APP_DASHBOARD_URL}/login`,
		classes: 'primary',
	},
];

class DefaultNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navbarExpanded: false,
	    sticky: props.sticky || false,
	    openedDropDown: null,
    };

    this.navbar = React.createRef();

    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  toggleNavbar = () => {
    this.setState(prevState => ({
      navbarExpanded: !prevState.navbarExpanded
    }));
    setTimeout(this.handleScroll, 0);
  }

	toggleDropDown = (openedDropDown) => {
    this.setState(_ => ({
	    openedDropDown: this.state.openedDropDown === openedDropDown ? null : openedDropDown,
    }));
  };

  closeDropDown = () => {
    this.setState(_ => ({
	    openedDropDown: null,
    }));
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
	  const navigation = this.navbar.current;

  	if (navigation && !this.props.sticky) {
		  const navTop = navigation.offsetTop + navigation.offsetHeight;

		  if (window.scrollY >= navTop) {
			  navigation.classList.add("navbar-sticky");
			  this.setState({
				  sticky: true,
			  })
		  } else {
			  navigation.classList.remove("navbar-sticky");
			  this.setState({
				  sticky: false,
			  })
		  }
	  }
  };

	closeExpandedNavIfRequired = () => {
		if (this.state.navbarExpanded) {
			this.toggleNavbar();
		}
	};

  render() {
  	const { openedDropDown } = this.state;
    const useSpaNav = this.props.useSpaNav;
    let  LinkComponent;

    if (useSpaNav) {
	    LinkComponent = ScrollLink;
    } else {
	    LinkComponent = RedirectLink;
    }

    function getPath(hash) {
    	if (useSpaNav) {
    		return hash;
	    }

	    return `/#${hash}`;
    }

    return (
    	<>
		    <div className={classnames('nav-background', {
		    	'active': !!openedDropDown,
		    })} onClick={() => this.closeDropDown()} onKeyDown={() => this.closeDropDown()}></div>
	      <nav
	        ref={this.navbar}
	        className={classNames(
	          "navbar navbar-expand-md main-nav navigation fixed-top sidebar-right",
	          {
	          	"navbar-expanded": this.state.navbarExpanded,
		          'navbar-sticky': this.props.sticky,
	          }
	        )}
	      >
	        <Container size="tiny">
	          <button
	            className="navbar-toggler"
	            type="button"
	            onClick={this.toggleNavbar}
	          >
	            <span className="icon-bar" />
	            <span className="icon-bar" />
	            <span className="icon-bar" />
	          </button>

		        <div className="logo">
		          <NavbarBrand className="mr-0" tag={LinkComponent} to="/" onClick={this.closeExpandedNavIfRequired}>
			          <div className="nav-link">
                  <Link to="/">
                    {this.state.sticky ? (
                      <img src={ LogoDark } alt="Mockless.com" width="140px" />
                    ) : (
                      <img src={ LogoWhite } alt="Mockless.com" width="140px" />
                    )}
                  </Link>
			          </div>
		          </NavbarBrand>
		        </div>

	          <div className="collapse navbar-collapse">
	            <div className="sidebar-brand">
		            <LinkComponent to={getPath('home')} smooth={true} onClick={this.closeExpandedNavIfRequired}>
			            <div className="nav-link">
                    <img src={ LogoDark } alt="Mockless.com" />
			            </div>
	              </LinkComponent>
	            </div>

		          <Navigator
			          navItems={navItems}
			          openedDropDown={openedDropDown}
			          onDropDownClick={this.toggleDropDown}
			          onInternalClick={this.closeExpandedNavIfRequired}/>
	          </div>
	        </Container>

		      <NavigatorDropdowns
			      openedDropDown={openedDropDown}
		        navItems={navItems}
		        onInternalClick={() => this.closeDropDown() && this.closeExpandedNavIfRequired()} />
	      </nav>
	    </>
    );
  }
}

export default DefaultNavbar;
